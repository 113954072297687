import BaseFilter from '../modules/filters/baseFilter';
import mobileCarousel from '../utils/mobileCarousel';

const sections = document.querySelectorAll('.recruitment-process');

sections.forEach(section => {
    const filterSwitcherWrapper = section.querySelector('.filter-switcher');
    const tabsContents = section.querySelectorAll(
        '.recruitment-process__tab-content'
    );
    let filterSwitcherController = null;

    filterSwitcherController = new BaseFilter(filterSwitcherWrapper, section);

    // Tab

    const openTab = e => {
        const { detail } = e;
        const parseddDetail = parseInt(detail.value);

        if (
            !tabsContents[parseddDetail].classList.contains(
                'recruitment-process__tab-content--active'
            )
        ) {
            document
                .querySelector('.recruitment-process__tab-content--active')
                .classList.remove('recruitment-process__tab-content--active');
            tabsContents[parseddDetail].classList.add(
                'recruitment-process__tab-content--active'
            );
        }
    };

    section.addEventListener('filter-switcher-change', e => openTab(e));

    // Karuzela

    mobileCarousel(
        'recruitment-process__steps-mobile-carousel',
        'swiper-pagination-recruitment-process',
        1.4,
        16,
        false
    );

    // Opisy

    const handleReadMore = (button, description, firstTextContent) => {
        const hideText = button.getAttribute('data-hide-text');

        if (
            description.classList.contains(
                'recruitment-process__box-description--trim'
            )
        ) {
            description.classList.remove(
                'recruitment-process__box-description--trim'
            );
            button.textContent = hideText;
        } else {
            description.classList.add(
                'recruitment-process__box-description--trim'
            );
            button.textContent = firstTextContent;
        }
    };

    const trimStepDescription = (
        description,
        absoluteDescription,
        readMoreButton,
        firstTextContent
    ) => {
        const windowWidth = window.innerWidth;
        if (windowWidth > 991.98) return;

        const heightOfAbsoluteElement =
            absoluteDescription.getBoundingClientRect().height;

        description.classList.add('recruitment-process__box-description--trim');
        readMoreButton.classList.add('read-more');
        readMoreButton.textContent = firstTextContent;

        if (windowWidth > 991.98) {
            // dwie linie
        } else if (windowWidth <= 991.98 && windowWidth > 575.98) {
            const lineHeight = 28;

            if (heightOfAbsoluteElement / lineHeight > 4) {
                description.classList.add(
                    'recruitment-process__box-description--trim'
                );
                readMoreButton.classList.add('read-more');
            } else {
                description.classList.remove(
                    'recruitment-process__box-description--trim'
                );
                readMoreButton.classList.remove('read-more');
            }
        } else {
            const lineHeight = 26;

            if (heightOfAbsoluteElement / lineHeight > 4) {
                description.classList.add(
                    'recruitment-process__box-description--trim'
                );
                readMoreButton.classList.add('read-more');
            } else {
                description.classList.remove(
                    'recruitment-process__box-description--trim'
                );
                readMoreButton.classList.remove('read-more');
            }
        }
    };

    tabsContents.forEach(tabContent => {
        const steps = tabContent.querySelectorAll('.recruitment-process__box');
        steps.forEach(step => {
            const description = step.querySelector(
                '.recruitment-process__box-description--current'
            );
            const absoluteDescription = step.querySelector(
                '.recruitment-process__box-description--absolute'
            );
            const readMoreButton = step.querySelector(
                '.recruitment-process__box-button'
            );
            const firstTextContent = readMoreButton
                ? readMoreButton.textContent
                : false;

            if (description && absoluteDescription && readMoreButton) {
                trimStepDescription(
                    description,
                    absoluteDescription,
                    readMoreButton,
                    firstTextContent
                );

                window.addEventListener('resize', () =>
                    trimStepDescription(
                        description,
                        absoluteDescription,
                        readMoreButton,
                        firstTextContent
                    )
                );
                section.addEventListener('filter-switcher-change', () =>
                    trimStepDescription(
                        description,
                        absoluteDescription,
                        readMoreButton,
                        firstTextContent
                    )
                )
            }

            // if (readMoreButton.classList.contains('read-more')) {
            if (readMoreButton) {
                readMoreButton.addEventListener('click', () =>
                    handleReadMore(
                        readMoreButton,
                        description,
                        firstTextContent
                    )
                );
            }
            // }
        });
    });
});
